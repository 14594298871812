import React from 'react';
import uniqid from 'uniqid';
import Step from './Step/Step';
import styles from './PlanningRow.module.scss';

export default function PlanningRow({
  planning,
  width,
}) {
  return (
    <div
      className={styles.container}
      style={{
        width,
      }}>
      <div className={styles.training}>
        {planning.length > 0 && planning?.map((step) => <Step
          key={uniqid()}
          step={step}
        />)}
      </div>
    </div>
  );
}
