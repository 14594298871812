import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { postFileAction, deleteFileAction } from '../../../../actions/trainingsActions';

import InputFile from '../../../../lib/InputFile/InputFile';

import styles from './Files.module.scss';
import FileItem from '../../../../components/FileItem/FileItem';
import { ERROR_TRAININGS, POST_FILE } from '../../../../actions/types';

export default function Files({
  submit,
  isEdit,
}) {
  const dispatch = useDispatch();
  const { file, training, error } = useSelector((state) => state.trainings);
  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type: POST_FILE,
      payload: null,
    });
    dispatch({
      type: ERROR_TRAININGS,
      payload: null,
    });
  }, [dispatch]);

  function handleChangeFile(fileData) {
    console.log(fileData);
    if (!fileData) {
      return null;
    }
    setFileError();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('title', fileData.name);
    formData.append('description', `document ${fileData.name}`);
    formData.append('alt', `${fileData.name.split(' ').join('-')}-file`);
    document.getElementById('file').value = '';
    return postFileAction(dispatch, formData);
  }

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    };
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (file && file._id) {
      setIsLoading(false);
      submit({ files: [...training.files.map((d) => d._id), file._id] });
      clearFile();
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(() => {
    if (error && error.data && error.data.error) {
      if (error.data.error === 'Extension not allowed.') {
        setFileError("Ce type de fichier n'est pas autorisé");
        setIsLoading(false);
        clearFile();
      }
    }
    // eslint-disable-next-line
  },[error]);

  function deleteFile(id) {
    deleteFileAction(dispatch, id);
    submit({ files: training.files.map((d) => d._id).filter((f) => f !== id) });
  }

  return (
    <div className={styles.container}>
      <h2>Fichiers</h2>
      <div className={styles.files}>
        {training?.files.map((f) => (<FileItem
          key={f._id}
          file={f}
          styles={styles}
          isEdit={isEdit}
          deleteFile={deleteFile}
          />
        ))}
      </div>
      {isEdit
        && <div className={styles.input}>
          <InputFile
            name="file"
            isLoading={isLoading}
            withErrors={true}
            defaultValue={null}
            onChange={(files) => handleChangeFile(files[0])}
            error={fileError}
          />
        </div>
      }
    </div>
  );
}
