import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { MdChevronLeft } from 'react-icons/md';
import { FaCopy } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';

import {
  getTrainingAction,
  putTrainingAction,
  postTrainingAction,
  deleteTrainingAction,
} from '../../actions/trainingsActions';

import Button from '../../lib/HooksFormFields/Button';
import DatePicker from '../../lib/HooksFormFields/DatePicker';
import ErrorField from '../../lib/HooksFormFields/ErrorField';
import Files from './sections/Files/Files';
import Header from './sections/Header/Header';

import styles from './Training.module.scss';
import Links from './sections/Links/Links';
import Participants from './sections/Participants/Participants';
import Checkbox from '../../lib/HooksFormFields/Checkbox';
// import Files from './sections/Files/Files';

export default function Training() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useSelector((state) => state.auth);
  const { training, lists } = useSelector((state) => state.trainings);
  const mainRef = useRef();
  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState: {
      errors,
    },
    // setError,
  } = useForm();

  const [isEdit, setIsEdit] = useState(false);

  const getTraining = useCallback(() => {
    getTrainingAction(dispatch, id);
  }, [dispatch]);

  function initValues(object) {
    /* eslint-disable-next-line */
    for (const value of Object.entries(object)) {
      const values = ['name', 'ref', 'type', 'drug', 'therapeuticArea', 'course', 'sharepointUrl', 'former', 'quiz', 'description', 'isArchived', 'urls', 'participants'];
      const dates = ['validityDate', 'startDate'];
      if (values.find((v) => v === value[0])) {
        setValue(value[0], value[1]);
      }
      if (dates.find((v) => v === value[0]) && value[1]) {
        setValue(value[0], new Date(value[1]));
      }
    }
    return null;
  }

  function deleteTraining() {
    // eslint-disable-next-line no-alert
    if (window.confirm('Souhaitez-vous supprimer la formation')) {
      deleteTrainingAction(dispatch, training._id);
    }
  }

  function duplicateTraining() {
    const values = getValues();
    return postTrainingAction(dispatch, { ...values, name: `${getValues('name')}-copie` });
  }

  function submitTraining(val) {
    let values = getValues();
    if (val) {
      values = { ...values, ...val };
    }
    if (training?._id) {
      return putTrainingAction(dispatch, training._id, values);
    }
    return postTrainingAction(dispatch, values);
  }

  useEffect(() => {
    if (id) {
      getTraining();
    } else {
      setIsEdit(true);
    }
  }, []);

  useEffect(() => {
    if (!training) return null;
    return initValues(training);
  }, [training]);

  useEffect(() => {
    if (toast?.message === 'la formation a été supprimée') {
      navigate('/training');
    }
    if (id && toast?.message === 'la formation a été créée') {
      navigate('/training');
    }
  }, [toast]);

  return (
    <>
      <div className={styles['edit-toggle']}>
        {isEdit
          ? <Button
            handleClick={handleSubmit(submitTraining)}
          >
            SAUVEGARDER
          </Button>
          : <Button
            handleClick={() => setIsEdit(true)}
          >
            EDITER
          </Button>
        }
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <aside>
            <Link className={styles.link} to={'/training'}>
              <MdChevronLeft size={30} />  RETOUR À LA LISTE
            </Link>

            {isEdit
              ? <div className={styles.dates}>
                  <label>Date de début</label>
                  <DatePicker
                    name="startDate"
                    control={control}
                    className={'primary'}
                    required
                    placeholder="Séléctionner une date..."
                  />
                  <div className={styles.error}>
                    <ErrorField message={errors?.startDate?.message || ''} />
                  </div>
                  <label>Date de fin de validité</label>
                  <DatePicker
                    name="validityDate"
                    control={control}
                    className={'primary'}
                    required
                    placeholder="Séléctionner une date..."
                  />
                  <div className={styles.error}>
                    <ErrorField message={errors?.validityDate?.message || ''} />
                  </div>

              </div>
              : <div className={styles.dates}>
                {watch('startDate')
                  && <> <label>Date de début</label>
                    <p className={styles.date}>
                      {format(watch('startDate'), 'dd/MM/yyyy')}
                    </p>
                  </>
                }
                {watch('validityDate')
                  && <> <label>Date de fin de validité</label>
                    <p className={styles.date}>
                      {format(watch('validityDate'), 'dd/MM/yyyy')}
                    </p>
                  </>
                }
              </div>
            }

            <hr/>
            <Files
              control={control}
              isEdit={isEdit}
              submit={submitTraining}
            />
            <hr/>
            <Links
              links={watch('urls') || []}
              isEdit={isEdit}
              handleChange={(array) => setValue('urls', array)}
              />
            <hr/>
            {training?._id && <div
                className={`${styles.archived} ${isEdit ? styles.edit : ''}`}>
                <Checkbox
                  control={control}
                  name={'isArchived'}
                  label={'Formation archivée'}
                />
              </div>}
            {isEdit
              && <>
              {training?._id && <button
                onClick={() => duplicateTraining()}
                className={styles.duplicate}>
                <FaCopy size={18} /> Dupliquer la formation
              </button>}
              {training?._id && <button
                onClick={() => deleteTraining()}
                className={styles.delete}>
                <AiFillDelete size={20} /> Supprimer la formation
              </button>}
            </>
            }
          </aside>
          <main>
            <div ref={mainRef}>
              <Header
                isEdit={isEdit}
                watch={watch}
                setValue={setValue}
                submit={(obj) => submitTraining(obj)}
                control={control}
                lists={lists}
              />
              <Participants
                participants={watch('participants') || []}
                isEdit={isEdit}
                lists={lists}
                handleChange={(array) => submitTraining({ participants: array })}
                />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
