import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalRouter from './pages/GlobalRouter';
import MobilePage from './pages/Mobile/MobilePage';
import useWindowDimension from './utils/useWindowDimension';

function App() {
  const window = useWindowDimension();
  return (
    <div className="app-container">
      {window?.width >= 1024
        ? <BrowserRouter>
          <GlobalRouter />
        </BrowserRouter>
        : <MobilePage />
      }
    </div>
  );
}

export default App;
