import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import checkboxChecked from './checkbox-checked.svg';
import checkbox from './checkbox.svg';
import styles from './checkbox.module.scss';

const Checkbox = ({
  name,
  control,
  rules,
  label,
}) => {
  const [selected, setSelected] = useState(false);

  return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: {
            // name,
            onChange,
            value,
            // onBlur,
          },
        }) => {
          function handleClick() {
            const bool = !value;
            onChange(bool);
            setSelected(bool);
          }

          return (
            <div className={value ? `${styles.checkbox} ${styles.selected}` : `${styles.checkbox}`}>
              <>
                {value
                  ? <img className="img-checkbox" src={checkboxChecked} alt="checkbox checked" onClick={handleClick} />
                  : <img className="img-checkbox" src={checkbox} alt="checkbox" onClick={handleClick} />
                }
              </>
              { label
                && <label className={selected ? `${styles.selected}` : ''} onClick={handleClick} htmlFor={`checkbox-${name}`}>
                    {label}
                </label>
              }
            </div>
          );
        }

        }
      />

  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  label: PropTypes.string,
};

export default Checkbox;
