import React from 'react';
import uniqid from 'uniqid';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import styles from './Step.module.scss';

export default function Step({
  step,
}) {
  return (
    <div
      className={`${styles.step}`}
      style={{ ...step.styles, left: step.styles.left - 5 }}
    >
      <div className={`${styles[step.className]} ${step.tooltips?.length > 1 ? styles['same-date'] : ''}`}>
      </div>
      {step.label && new Date(step.date)?.getTime()
        && <div className={styles.tooltip}>
          <p>
            <label>{step.label}</label><br/>
            {step.date !== 'Invalid Date' && format(new Date(step.date), 'dd.MM.yyyy')}
        </p>
      </div>}
      {step.tooltips
        && <div className={styles.tooltip}>
          {step.tooltips.map((t) => {
            if (new Date(t.date)?.getTime()) {
              return (
                <p key={uniqid()}>
                  <label>{t.label}</label><br/>
                  {t.name && <><label>{t.name}</label><br/></>}
                  {format(new Date(t.date), 'dd.MM.yyyy')}
                </p>
              );
            }
            return null;
          })}
      </div>}
      {step.trainingId && step.name
        && <Link to={`/training/${step.trainingId}`}>{step.name}</Link>
      }
    </div>
  );
}
