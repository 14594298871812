/* eslint-disable no-case-declarations */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '../../lib/HooksFormFields/Button';
import Filters from '../../components/TrainingsFilters/Filters';

import styles from './TrainingsList.module.scss';
import SortedList from '../../components/SortedList/SortedList';
import GroupedList from '../../components/GroupedList/GroupedList';
import { GET_TRAINING } from '../../actions/types';
import { getTrainingsOptionsListAction } from '../../actions/trainingsActions';

export default function TrainingsList() {
  const dispatch = useDispatch();
  const { trainingsList, trainingsFilters, lists } = useSelector((state) => state.trainings);

  const divisionsList = lists?.divisions || [];
  const usersList = lists?.users || [];
  const coursesList = lists?.courses.map((d) => ({ value: d, label: d })) || [];
  const typesList = lists?.types || [];

  const [filtredList, setFiltredList] = useState();
  const [list, setList] = useState();
  const [selectedList, setSelectedList] = useState({ type: 'normal', list: [] });

  function handleChangeList(type, array) {
    if (type === 'normal') {
      setSelectedList({ type, list: array });
    }
    if (type === 'person') {
      const groupedUsers = [];
      usersList.filter((u) => {
        if (trainingsFilters.divisions.length > 0) {
          let found = false;
          trainingsFilters.divisions.forEach((key) => {
            if (u.division.find((d) => d === key)) found = true;
          });
          return found ? u : null;
        }
        return u;
      }).forEach((u) => {
        const trainings = [];
        const name = u?.profile?.lastName ? `${u.profile.lastName} ${u.profile.firstName}` : '';
        array.forEach((t) => {
          t.participants.forEach((p) => {
            if (p.user === u._id && !trainings.find((tr) => tr._id === t._id)) {
              return trainings.push({ ...t, date: p.date, isPresent: p.isPresent });
            }
            return null;
          });
        });
        if (trainings.length) {
          groupedUsers.push({
            name,
            list: trainings.map((t) => ({ ...t, key: `${name} ${t._id}` })),
          });
        }
      });
      setSelectedList({
        type,
        list: groupedUsers.sort((a, b) => b.list.length - a.list.length),
      });
    }
    if (type === 'division') {
      const groupedDivisions = [];
      divisionsList.filter((d) => {
        if (trainingsFilters.divisions.length > 0) {
          let found = false;
          trainingsFilters.divisions.forEach((key) => {
            if (d.value === key) found = true;
          });
          return found ? d : null;
        }
        return d;
      }).forEach((d) => {
        const trainings = [];
        array.forEach((t) => {
          t.participants.forEach((p) => {
            const user = lists?.users.find((u) => p.user === u._id);
            const found = user.division.find((ud) => ud === d.value);
            if (found && !trainings.find((tr) => tr._id === t._id)) {
              return trainings.push(t);
            }
            return null;
          });
        });
        if (trainings.length) {
          groupedDivisions.push({
            name: d.label,
            list: trainings.map((t) => ({ ...t, key: `${d.label}-${t._id}` })),
          });
        }
      });
      setSelectedList({
        type,
        list: groupedDivisions.sort((a, b) => b.list.length - a.list.length),
      });
    }
    if (type === 'course') {
      const groupedParcours = [];
      coursesList.forEach((d) => {
        const trainings = [...array].filter((t) => {
          const found = t.course === d.label;
          if (found) {
            return t;
          }
          return null;
        });
        if (trainings.length) {
          groupedParcours.push({
            name: d.label,
            list: trainings.map((t) => ({ ...t, key: `${d.label}-${t._id}` })),
          });
        }
      });
      setSelectedList({
        type,
        list: groupedParcours.sort((a, b) => b.list.length - a.list.length),
      });
    }
    if (type === 'type') {
      const groupedType = [];
      typesList.forEach((d) => {
        const trainings = [...array].filter((t) => {
          const found = t.type === d.label;
          if (found) {
            return t;
          }
          return null;
        });
        if (trainings.length) {
          groupedType.push({
            name: d.label,
            list: trainings.map((t) => ({ ...t, key: `${d.label}-${t._id}` })),
          });
        }
      });
      setSelectedList({
        type,
        list: groupedType.sort((a, b) => b.list.length - a.list.length),
      });
    }
  }

  const resetTraining = useCallback(() => {
    getTrainingsOptionsListAction(dispatch);
    dispatch({
      type: GET_TRAINING,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    resetTraining(dispatch);
  }, []);

  useEffect(() => {
    if (trainingsList && lists) {
      const initList = trainingsList.sort((a, b) => {
        if (new Date(a.updatedAt) < new Date(b.updatedAt)) return -1;
        if (new Date(a.updatedAt) > new Date(b.updatedAt)) return 1;
        return 0;
      });
      setFiltredList(initList.reverse());
    }
    // eslint-disable-next-line
  },[trainingsList, lists]);

  useEffect(() => {
    setSelectedList();
    setList();
    const newList = filtredList?.filter((t) => {
      if (trainingsFilters.therapeuticAreas.length > 0) {
        let found = false;
        trainingsFilters.therapeuticAreas.forEach((key) => {
          if (t.therapeuticArea === key) found = true;
        });
        return found ? t : null;
      }
      return t;
    }).filter((t) => {
      if (trainingsFilters.drugs.length > 0) {
        let found = false;
        trainingsFilters.drugs.forEach((key) => {
          if (t.drug === key) found = true;
        });
        return found ? t : null;
      }
      return t;
    }).filter((t) => {
      if (trainingsFilters.plannedDates.length > 0) {
        let found = false;
        t.participants.forEach((p) => {
          if (trainingsFilters.plannedDates.find((k) => k === new Date(p.date).getFullYear())) {
            found = true;
          }
        });
        return found ? t : null;
      }
      return t;
    }).filter((t) => {
      if (trainingsFilters.validityYearsList.length > 0) {
        let found = false;
        trainingsFilters.validityYearsList.forEach((key) => {
          if (new Date(t.validityDate).getFullYear() === key) found = true;
        });
        return found ? t : null;
      }
      return t;
    })
      .filter((t) => {
        if (trainingsFilters.archived.length === 1) {
          let found = false;
          if (trainingsFilters.archived[0] === 'true' && t.isArchived) found = true;
          if (trainingsFilters.archived[0] === 'false' && !t.isArchived) found = true;
          return found ? t : null;
        }
        return t;
      })
      .filter((v) => {
        if (!trainingsFilters?.search) return v;
        if (v?.name?.toLowerCase().includes(trainingsFilters?.search?.toLowerCase())) {
          return v;
        }
        return null;
      });
    setList(newList);
    handleChangeList(selectedList.type, newList);
  }, [filtredList, trainingsFilters]);

  return (
    <>
      <Filters />
      <div className={styles['btn-container']}>
        <Link to={'/training/create'}>
          <Button>
            NOUVELLE FORMATION
          </Button>
        </Link>
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles['list-toggle']}>
            <button
              className={selectedList.type === 'normal' ? styles.active : ''}
              onClick={() => handleChangeList('normal', list)}
              >
              Ne pas regrouper
            </button>
            <button
              className={selectedList.type === 'person' ? styles.active : ''}
              onClick={() => handleChangeList('person', list)}
              >
              par personnes
            </button>
            <button
              className={selectedList.type === 'division' ? styles.active : ''}
              onClick={() => handleChangeList('division', list)}
              >
              par département
            </button>
            <button
              className={selectedList.type === 'course' ? styles.active : ''}
              onClick={() => handleChangeList('course', list)}
              >
              par parcours
            </button>
            <button
              className={selectedList.type === 'type' ? styles.active : ''}
              onClick={() => handleChangeList('type', list)}
              >
              par type
            </button>
          </div>
          {selectedList.type === 'normal'
            && <SortedList
              lists={lists}
              type={selectedList.type}
              trainingsList={selectedList.list}
            />
          }
          {selectedList.type === 'division' || selectedList.type === 'course' || selectedList.type === 'type' || selectedList.type === 'person'
            ? <>
              {selectedList?.list
                .map((g, i) => <GroupedList
                key={g.name}
                index={i}
                group={g}
                type={selectedList.type}
                lists={lists}
              />)}
            </>
            : null
          }
        </div>
      </div>
    </>
  );
}
