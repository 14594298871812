import React from 'react';
import { Link } from 'react-router-dom';
import { BsCheckCircleFill } from 'react-icons/bs';
import { format } from 'date-fns';

export default function TrainingRow({
  training, styles, lists, type,
}) {
  const isToday = (someDate) => {
    const today = new Date();
    return someDate.getDate() === today.getDate()
      && someDate.getMonth() === today.getMonth()
      && someDate.getFullYear() === today.getFullYear();
  };
  return (
    <Link to={`/training/${training._id}`} className={`${styles.row}`}>
      {isToday(new Date(training.updatedAt)) && <div className={styles.updated}>

      </div>}
      <div className={`${styles.col} ${styles.name}`}>
        <p>{training.name}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{training.ref}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{training.type}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{lists?.drugs.find((t) => t._id === training?.drug)?.name}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{lists?.therapeuticAreas.find((t) => t._id === training?.therapeuticArea)?.name}</p>
      </div>
      {type === 'person' ? <div className={`${styles.col}`}>
          <p>{training.date && format(new Date(training.date), 'dd/MM/yyyy')}</p>
          {<div className={`${styles.present} ${training.isPresent ? styles.active : ''}`}>
            <BsCheckCircleFill size={18} />
          </div>
          }
        </div>
        : <div className={`${styles.col}`}>
          <p>{training.validityDate && format(new Date(training.validityDate), 'dd/MM/yyyy')}</p>
      </div>
      }
    </Link>
  );
}
