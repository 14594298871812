import React, { useState } from 'react';
import { BiMinus } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

export default function FilterList({
  name, list, activeList, setActiveList, styles,
}) {
  const [listIsOpen, setListIsOpen] = useState(false);
  const isAll = activeList.length === 0;

  function handleChangeActiveList(id) {
    let newList = [...activeList];
    if (activeList.find((d) => d === id)) {
      newList = activeList.filter((item) => item !== id);
    } else {
      newList.push(id);
    }
    setActiveList(newList);
  }

  return (
    <>
      <p>{name} {activeList.length > 0 && <span>({activeList.length})</span>} </p>
      <button
        onClick={() => setListIsOpen(!listIsOpen)}
        >
        {listIsOpen ? <BiMinus size={20} color={'#FFFFFF'}/> : <BsPlus size={20} color={'#FFFFFF'}/>}
      </button>
      {listIsOpen
        && <div className={styles.list}>
            <div
              onClick={() => setActiveList([])}
              className={`${styles.item} ${isAll ? ` ${styles.isActive}` : ''}`}>
              <div className={styles.option}>
                {isAll ? <MdRadioButtonChecked size={14} color={'#FFFFFF'}/> : <MdRadioButtonUnchecked size={14} color={'#FFFFFF'}/> }
                <p>Tous</p>
              </div>
            </div>
        {list.map((item, i) => {
          const isActive = activeList.find((d) => d === item.value);
          return (
              <div key={i}
                onClick={() => handleChangeActiveList(item.value)}
                className={`${styles.item} ${isActive ? ` ${styles.isActive}` : ''}`}>
                <div className={styles.option}>
                  {isActive ? <MdRadioButtonChecked size={14} color={'#FFFFFF'}/> : <MdRadioButtonUnchecked size={14} color={'#FFFFFF'}/> }
                  <p>{item.label}</p>
                  <div className={styles['color-circle']} style={{ backgroundColor: item.color }}></div>
                </div>
              </div>
          );
        })}
      </div>
      }
    </>
  );
}
