import { isSameDay } from 'date-fns';
import React, {
  useCallback, useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uniqid from 'uniqid';
import { getTrainingsListAction } from '../../actions/trainingsActions';
import GroupedPlanning from '../../components/GroupedPlanning/GroupedPlanning';
import Filters from '../../components/TrainingsFilters/Filters';
// import PlanningRow from '../../components/PlanningRow/PlanningRow';

// import Filters from '../../components/PlanningFilters/Filters';
// import PlanningProductRow from '../../components/PlanningProductRow/PlanningProductRow';

import styles from './Planning.module.scss';

export default function Planning() {
  const dispatch = useDispatch();
  const { trainingsList, trainingsFilters, lists } = useSelector((state) => state.trainings);
  const today = new Date();
  const yearWidth = 1200;
  const monthRef = useRef();
  const contentRef = useRef();

  const divisions = lists?.divisions || [];
  const courses = lists?.courses.map((d) => ({ value: d, label: d })) || [];
  const typesList = lists?.types || [];
  // const users = lists?.users.map((u) => {
  //   // eslint-disable-next-line prefer-template
  //   const name = u.profile.firstName + ' ' + u.profile.lastName;
  //   return { value: u._id, label: name, divisions: u.division };
  // }) || [];
  const defaultPlanning = {
    training: [],
    participant: [],
    course: [],
    type: [],
    division: [],
  };
  const [planningsData, setPanningData] = useState(defaultPlanning);
  const [selectedList, setSelectedList] = useState('training');
  const [groupedPlannings, setGroupedPlannings] = useState();
  const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });
  // const [productsOptions, setProductsOptions] = useState();
  const [dates, setDates] = useState();

  const initData = useCallback(() => {
    getTrainingsListAction(dispatch);
  }, [dispatch]);

  function distBeetweenDates(first, second) {
    const numberOfDays = Math.round((second - first) / (1000 * 60 * 60 * 24));
    return numberOfDays * (yearWidth / 365);
  }

  function getMonth(d) {
    let isActiveMonth = false;
    const month = new Date(d);
    if (today.getFullYear() === month.getFullYear() && today.getMonth() === month.getMonth()) {
      isActiveMonth = true;
    }
    return ({
      className: `${styles.month} ${isActiveMonth ? styles['is-active'] : ''}`,
      isActive: isActiveMonth,
    });
  }

  function handleScroll(e) {
    setScrollPos({
      x: e.target.scrollLeft,
      y: e.target.scrollTop,
    });
  }

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (trainingsList && lists && selectedList) {
      // init dates
      const filtered = trainingsList?.filter((t) => {
        if (trainingsFilters.therapeuticAreas.length > 0) {
          let found = false;
          trainingsFilters.therapeuticAreas.forEach((key) => {
            if (t.therapeuticArea === key) found = true;
          });
          return found ? t : null;
        }
        return t;
      }).filter((t) => {
        if (trainingsFilters.drugs.length > 0) {
          let found = false;
          trainingsFilters.drugs.forEach((key) => {
            if (t.drug === key) found = true;
          });
          return found ? t : null;
        }
        return t;
      }).filter((t) => {
        if (trainingsFilters.validityYearsList.length > 0) {
          let found = false;
          trainingsFilters.validityYearsList.forEach((key) => {
            if (new Date(t.validityDate).getFullYear() === key) found = true;
          });
          return found ? t : null;
        }
        return t;
      }).filter((t) => {
        if (trainingsFilters.plannedDates.length > 0) {
          let found = false;
          t.participants.forEach((p) => {
            if (trainingsFilters.plannedDates.find((k) => k === new Date(p.date).getFullYear())) {
              found = true;
            }
          });
          return found ? t : null;
        }
        return t;
      })
        .filter((t) => {
          if (trainingsFilters.archived.length === 1) {
            let found = false;
            if (trainingsFilters.archived[0] === 'true' && t.isArchived) found = true;
            if (trainingsFilters.archived[0] === 'false' && !t.isArchived) found = true;
            return found ? t : null;
          }
          return t;
        })
        .filter((v) => {
          if (!trainingsFilters?.search) return v;
          if (v?.name?.toLowerCase().includes(trainingsFilters?.search?.toLowerCase())) {
            return v;
          }
          return null;
        });

      let startDate = new Date();
      let endDate = new Date();
      filtered?.forEach((t) => {
        const keys = ['startDate', 'validityDate'];
        keys.forEach((k) => {
          if (t[k] && new Date(t[k]) < startDate) {
            startDate = new Date(t[k]);
          }
          if (t[k] && new Date(t[k]) > endDate) {
            endDate = new Date(t[k]);
          }
        });
        t?.participants.forEach((c) => {
          if (c.date && new Date(c.date) < startDate) {
            startDate = new Date(c.date);
          }
          if (c.date && new Date(c.date) > endDate) {
            endDate = new Date(c.date);
          }
        });
      });
      const datesList = [];
      let date = new Date(startDate).setDate(1);
      do {
        datesList.push(new Date(date).toUTCString());
        date = new Date(date).setMonth(new Date(date).getMonth() + 1);
      } while (date < new Date(endDate).setMonth(new Date(endDate).getMonth() + 1));
      setDates(datesList);

      // init products
      const plannings = { ...defaultPlanning };
      filtered?.forEach((t) => {
        let offset = 0;
        const pDuration = {
          start: new Date(t.startDate) || new Date(),
          end: new Date(t.validityDate) || new Date(),
        };
        // Init trainings
        const trainingSteps = [];
        offset = distBeetweenDates(
          new Date(startDate).setDate(1),
          new Date(pDuration.start),
        ) - 8;
        trainingSteps.push({
          _id: uniqid(),
          className: 'date',
          label: 'Début de validité',
          type: t.type,
          date: new Date(pDuration.start),
          styles: { left: offset, width: 16 },
        });
        offset = distBeetweenDates(
          new Date(startDate).setDate(1),
          new Date(pDuration.end),
        ) - 8;
        trainingSteps.push({
          _id: uniqid(),
          className: 'end',
          label: 'Fin de la validité',
          date: new Date(pDuration.end),
          styles: { left: offset, width: 16 },
        });
        plannings.training.push({
          name: t.name,
          _id: t.name,
          trainingId: t._id,
          list: trainingSteps,
        });
        t.participants.filter((p) => {
          if (trainingsFilters.divisions.length > 0) {
            let found = false;
            const user = lists?.users.find((u) => p.user === u._id);
            trainingsFilters.divisions.forEach((key) => {
              if (user.division.find((d) => d === key)) found = true;
            });
            return found ? p : null;
          }
          return p;
        }).forEach((p) => {
          const user = lists?.users.find((u) => p.user === u._id);
          // eslint-disable-next-line prefer-template
          const name = user?.profile?.lastName ? user.profile.lastName + ' ' + user.profile.firstName : '';
          // eslint-disable-next-line prefer-template
          const label = t.name;
          offset = distBeetweenDates(
            new Date(startDate).setDate(1),
            new Date(p.date),
          );

          let tooltips = [{ label: name, date: p.date }];
          let step = {
            _id: uniqid(),
            user: p.user,
            className: 'planned',
            tooltips,
            date: new Date(p.date),
            styles: { left: offset - 10, width: 16 },
          };

          const trainingIndex = plannings?.training?.findIndex((di) => di.trainingId === t._id);
          if (trainingIndex !== -1) {
            const stepsList = plannings?.training[trainingIndex].list;
            const sameDateIndex = stepsList?.findIndex(
              (s) => isSameDay(s.date, new Date(p.date)),
            );
            if (sameDateIndex !== -1 && stepsList[sameDateIndex].tooltips) {
              stepsList[sameDateIndex].tooltips = [
                ...stepsList[sameDateIndex].tooltips,
                ...tooltips,
              ];
            } else {
              stepsList.push(step);
            }
            plannings.training[trainingIndex].list = stepsList;
          }

          tooltips = [{ label, date: p.date }];
          step = {
            _id: uniqid(),
            user: p.user,
            className: 'planned',
            tooltips,
            date: new Date(p.date),
            styles: { left: offset - 10, width: 16 },
          };

          const index = plannings.participant.findIndex((di) => di._id === p.user);
          if (index !== -1) {
            const stepsList = [...plannings.participant[index].list];
            const sameDateIndex = stepsList.findIndex((s) => isSameDay(s.date, new Date(p.date)));
            if (sameDateIndex !== -1) {
              stepsList[sameDateIndex].tooltips = [
                ...stepsList[sameDateIndex].tooltips,
                ...tooltips,
              ];
            } else {
              stepsList.push(step);
            }
            plannings.participant[index] = {
              ...plannings.participant[index],
              list: stepsList,
            };
          } else {
            plannings.participant.push({
              name,
              _id: user._id,
              list: [step],
            });
          }
        });
        // calc participant Duration
        plannings.participant.forEach((p) => {
          let lastStepDate = startDate;
          p.list.forEach((step) => {
            if (new Date(step.date) > lastStepDate) {
              lastStepDate = new Date(step.date);
            }
          });
          p.list.push({
            _id: uniqid(),
            className: 'duration-line',
            styles: {
              left: 0,
              width: distBeetweenDates(new Date(startDate).setDate(1), new Date(lastStepDate)),
            },
          });
        });

        // calc training duration
        plannings.training.forEach((tr) => {
          let lastStepDate = startDate;
          let fistStepDate = pDuration.start;
          tr.list.forEach((st) => {
            if (new Date(st.date) > lastStepDate) {
              lastStepDate = new Date(st.date);
            }
            if (new Date(st.date) < fistStepDate) {
              fistStepDate = new Date(st.date);
            }
          });
          offset = distBeetweenDates(new Date(startDate).setDate(1), new Date(fistStepDate));
          if (!tr.list?.find((st) => st.className === 'duration')) {
            tr.list.push({
              _id: uniqid(),
              className: 'duration',
              trainingId: tr.trainingId,
              name: tr.name,
              styles: {
                left: offset,
                width: distBeetweenDates(new Date(fistStepDate), new Date(lastStepDate)),
              },
            });
          }
        });

        // INIT COURSES
        courses.forEach((c) => {
          if (c.label !== t.course) return null;
          t.participants.forEach((p) => {
            offset = distBeetweenDates(
              new Date(startDate).setDate(1),
              new Date(p.date),
            );
            const tooltips = [{ label: t.name, date: p.date }];
            const step = {
              _id: uniqid(),
              className: 'planned',
              tooltips,
              date: new Date(p.date),
              styles: { left: offset - 10, width: 16 },
            };
            const index = plannings.course.findIndex((di) => di._id === c.value);
            if (index !== -1) {
              const stepsList = [...plannings.course[index].list];
              const sameDateIndex = stepsList.findIndex((s) => isSameDay(
                s.date,
                new Date(p.date),
              ));
              if (sameDateIndex !== -1) {
                if (!stepsList[sameDateIndex].tooltips.find((to) => to.label === t.name)) {
                  stepsList[sameDateIndex].tooltips = [
                    ...stepsList[sameDateIndex].tooltips,
                    ...tooltips,
                  ];
                }
              } else {
                stepsList.push(step);
              }
              plannings.course[index] = {
                ...plannings.course[index],
                list: stepsList,
              };
            } else {
              plannings.course.push({
                name: c.label,
                _id: c.label,
                list: [step],
              });
            }
            return null;
          });
          return null;
        });
        // CALC COURSES DURATION
        plannings.course.forEach((p) => {
          let lastStepDate = startDate;
          p.list.forEach((step) => {
            if (new Date(step.date) > lastStepDate) {
              lastStepDate = new Date(step.date);
            }
          });
          p.list.push({
            _id: uniqid(),
            className: 'duration-line',
            styles: {
              left: 0,
              width: distBeetweenDates(new Date(startDate).setDate(1), new Date(lastStepDate)),
            },
          });
        });
        // INIT TYPES
        typesList.forEach((ti) => {
          if (ti.label !== t.type) return null;
          t.participants.forEach((p) => {
            offset = distBeetweenDates(
              new Date(startDate).setDate(1),
              new Date(p.date),
            );
            const tooltips = [{ label: t.name, date: p.date }];
            const step = {
              _id: uniqid(),
              className: 'planned',
              tooltips,
              date: new Date(p.date),
              styles: { left: offset - 10, width: 16 },
            };
            const index = plannings.type.findIndex((di) => di._id === ti.label);
            if (index !== -1) {
              const stepsList = [...plannings.type[index].list];
              const sameDateIndex = stepsList.findIndex((s) => isSameDay(
                s.date,
                new Date(p.date),
              ));
              if (sameDateIndex !== -1) {
                if (!stepsList[sameDateIndex].tooltips.find((to) => to.label === t.name)) {
                  stepsList[sameDateIndex].tooltips = [
                    ...stepsList[sameDateIndex].tooltips,
                    ...tooltips,
                  ];
                }
              } else {
                stepsList.push(step);
              }
              plannings.type[index] = {
                ...plannings.type[index],
                list: stepsList,
              };
            } else {
              plannings.type.push({
                name: ti.label,
                _id: ti.label,
                list: [step],
              });
            }
            return null;
          });
          return null;
        });
        // CALC TYPS DURATION
        plannings.type.forEach((p) => {
          let lastStepDate = startDate;
          p.list.forEach((step) => {
            if (new Date(step.date) > lastStepDate) {
              lastStepDate = new Date(step.date);
            }
          });
          p.list.push({
            _id: uniqid(),
            className: 'duration-line',
            styles: {
              left: 0,
              width: distBeetweenDates(new Date(startDate).setDate(1), new Date(lastStepDate)),
            },
          });
        });
        // INIT DIVISIONS
        divisions.filter((d) => {
          if (trainingsFilters.divisions.length > 0) {
            let found = false;
            trainingsFilters.divisions.forEach((key) => {
              if (d.value === key) found = true;
            });
            return found ? d : null;
          }
          return d;
        }).forEach((d) => {
          t.participants.forEach((p) => {
            const user = lists?.users.find((u) => p.user === u._id);
            if (!user?.division.find((u) => u === d.value)) return null;
            // eslint-disable-next-line prefer-template
            const name = user?.profile?.lastName ? user.profile.lastName + ' ' + user.profile.firstName : '';
            // eslint-disable-next-line prefer-template
            const label = t.name;
            offset = distBeetweenDates(
              new Date(startDate).setDate(1),
              new Date(t.startDate),
            );
            const tooltips = [{ label, name, date: t.startDate }];
            const step = {
              _id: uniqid(),
              className: 'planned',
              tooltips,
              date: new Date(t.startDate),
              styles: { left: offset - 10, width: 16 },
            };
            const index = plannings.division.findIndex((di) => di._id === d.value);
            if (index !== -1) {
              const stepsList = [...plannings.division[index].list];
              const sameDateIndex = stepsList.findIndex((s) => isSameDay(
                s.date,
                new Date(t.startDate),
              ));
              if (sameDateIndex !== -1) {
                stepsList[sameDateIndex].tooltips = [
                  ...stepsList[sameDateIndex].tooltips,
                  ...tooltips,
                ];
              } else {
                stepsList.push(step);
              }
              plannings.division[index] = {
                ...plannings.division[index],
                list: stepsList,
              };
            } else {
              plannings.division.push({
                name: d.label,
                _id: d.label,
                list: [step],
              });
            }
            return null;
          });
          return null;
        });
        // CALC DIVISION DURATTION
        plannings.division.forEach((p) => {
          let lastStepDate = startDate;
          p.list.forEach((step) => {
            if (new Date(step.date) > lastStepDate) {
              lastStepDate = new Date(step.date);
            }
          });
          p.list.push({
            _id: uniqid(),
            className: 'duration-line',
            styles: {
              left: 0,
              width: distBeetweenDates(new Date(startDate).setDate(1), new Date(lastStepDate)),
            },
          });
        });

        // // calc training duration
        // plannings.training.forEach((tr) => {
        //   let lastStepDate = startDate;
        //   let fistStepDate = pDuration.start;
        //   tr.list.forEach((st) => {
        //     if (new Date(st.date) > lastStepDate) {
        //       lastStepDate = new Date(st.date);
        //     }
        //     if (new Date(st.date) < fistStepDate) {
        //       fistStepDate = new Date(st.date);
        //     }
        //   });
        //   offset = distBeetweenDates(new Date(startDate).setDate(1), new Date(fistStepDate));
        //   if (!tr.list?.find((st) => st.className === 'duration')) {
        //     tr.list.push({
        //       _id: uniqid(),
        //       className: 'duration',
        //       trainingId: tr.trainingId,
        //       name: tr.name,
        //       styles: {
        //         left: offset,
        //         width: distBeetweenDates(new Date(fistStepDate), new Date(lastStepDate)),
        //       },
        //     });
        //   }
        // });
      });
      setPanningData(plannings);
    }
    return null;
  }, [trainingsList, lists, trainingsFilters]);

  useEffect(() => {
    if (planningsData[selectedList]) {
      setGroupedPlannings(planningsData[selectedList]);
    } else {
      setGroupedPlannings();
    }
  }, [planningsData, selectedList]);

  useLayoutEffect(() => {
    if (monthRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        left: monthRef.current.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [monthRef.current, groupedPlannings]);

  return (
    <>
      <Filters />
      <div className={styles.header}>
        <div className={styles['list-toggle']}>
          <button
            className={selectedList === 'training' ? styles.active : ''}
            onClick={() => setSelectedList('training')}
            >
            Ne pas regrouper
          </button>
          <button
            className={selectedList === 'course' ? styles.active : ''}
            onClick={() => setSelectedList('course')}
            >
            Vue parcours
          </button>
          <button
            className={selectedList === 'division' ? styles.active : ''}
            onClick={() => setSelectedList('division')}
            >
            Vue départements
          </button>
          <button
            className={selectedList === 'participant' ? styles.active : ''}
            onClick={() => setSelectedList('participant')}
            >
            Vue personnes
          </button>
          <button
            className={selectedList === 'type' ? styles.active : ''}
            onClick={() => setSelectedList('type')}
            >
            Vue types
          </button>
        </div>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={`${styles.icon}`}>
            </div>
            <p>Début de validité</p>
          </div>
          <div className={styles.item}>
            <div className={`${styles.icon} ${styles.planned}`}>
            </div>
            <p>Date prévue</p>
          </div>
          <div className={styles.item}>
            <div className={`${styles.icon} ${styles.validity}`}>
            </div>
            <p>Fin de la validité</p>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.content} ref={contentRef} onScroll={(e) => handleScroll(e)}>
          {groupedPlannings?.length > 0
            && <>
            <div className={styles.dates} style={{ marginLeft: -(scrollPos.x - 2) }}>
              {groupedPlannings?.length && contentRef.current?.scrollHeight && dates?.map((d) => (
                <div
                  key={d.toString()}
                  ref={getMonth(d).isActive ? monthRef : null}
                  style={{
                    width: (yearWidth / 12),
                    minHeight: contentRef.current.scrollHeight + 100,
                  }}
                  className={getMonth(d).className}>
                  <p>{new Intl.DateTimeFormat('fr', { month: 'short', year: 'numeric' }).format(new Date(d))}</p>
                </div>
              ))}
            </div>
            <div
              className={styles.plannings}
              style={{ marginLeft: 374, minHeight: contentRef.current.scrollHeight }}>
              {groupedPlannings?.map((g) => <GroupedPlanning
                key={uniqid()}
                name={g.name}
                list={g.list}
                scrollPos={scrollPos}
                yearWidth
                datesCount={dates.length}
              />)}
            </div>
          </>
        }
        </div>
      </div>
    </>
  );
}
