import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import { BsCheckLg } from 'react-icons/bs';
import { useForm } from 'react-hook-form';

import DatePicker from '../../../../lib/HooksFormFields/DatePicker';
import StyledSelect from '../../../../lib/HooksFormFields/StyledSelect';
import SortButton from '../../../../lib/SortButton';
import styles from './Participants.module.scss';
import ParticipantRow from './ParticipantRow';

export default function Participants({
  participants = [],
  isEdit,
  lists,
  handleChange,
}) {
  const {
    control,
    getValues,
    watch,
    // setError,
  } = useForm();

  const initSortState = {
    user: true,
    date: true,
    divisions: true,
    isPresent: true,
  };
  const divisionsList = lists?.divisions || [];
  const users = lists?.users.map((u) => {
    // eslint-disable-next-line prefer-template
    const name = u.profile.firstName + ' ' + u.profile.lastName;
    return { value: u._id, label: name, divisions: u.division };
  }) || [];

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [sortedList, setSortedList] = useState([]);
  const [newParticipant, setNewParticipent] = useState({});
  const [addDivision, setAddDivision] = useState(false);
  const [newDivision, setNewDivision] = useState({});

  function handleChangeDivision(val) {
    const divisionUsers = [];
    users.forEach((u) => {
      if (u.divisions.find((d) => d === val.division)
        && !participants.find((p) => p.user === u.value)) {
        divisionUsers.push({ user: u.value, date: val.date, isPresent: false });
      }
    });
    handleChange([...participants, ...divisionUsers]);
    setAddDivision(false);
  }

  function sortList(type, isReverse) {
    let list = [...sortedList];
    switch (type) {
      case 'user':
        list = list.sort((a, b) => {
          if (a.user < b.user) return -1;
          if (a.user > b.user) return 1;
          return 0;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, user: isReverse });
        break;

      case 'isPresent':
        // eslint-disable-next-line no-nested-ternary
        list = list.sort((a, b) => ((a.isPresent === b.isPresent) ? 0 : a.isPresent ? -1 : 1));
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, isPresent: isReverse });
        break;

      case 'divisions':
        list = list.sort((a, b) => {
          const aDivisions = users.find((u) => u.value === a.user)?.divisions?.length;
          const bDivisions = users.find((u) => u.value === b.user)?.divisions?.length;
          if (aDivisions < bDivisions) return -1;
          if (aDivisions > bDivisions) return 1;
          return 0;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, divisions: isReverse });
        break;

      case 'date':
        list = list.sort((a, b) => {
          const aDate = a.date
            ? new Date(a.date) : new Date(a.date);
          const bDate = b.date
            ? new Date(b.date) : new Date(b.datel);
          return aDate - bDate;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, date: isReverse });
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    if (participants) {
      setSortedList(participants);
    }
    // eslint-disable-next-line
  },[participants]);

  return (
    <div className={styles.container}>
      <div className={styles.participants}>
        {!isEdit && !sortedList.length
          ? null
          : <>
            <div className={`${styles.row} ${styles.label}`}>
              <SortButton
                name={'Personnes concernées'}
                handleClick={() => sortList('user', !sortByReverse.user)}
                state={sortByReverse.user}
                className={styles.col}/>
              <SortButton
                name={'Divisions'}
                handleClick={() => sortList('divisions', !sortByReverse.divisions)}
                state={sortByReverse.divisions}
                className={styles.col}/>
              <SortButton
                name={'Date prévue'}
                handleClick={() => sortList('date', !sortByReverse.date)}
                state={sortByReverse.date}
                className={styles.col}/>
              <SortButton
                name={'Présence'}
                handleClick={() => sortList('isPresent', !sortByReverse.isPresent)}
                state={sortByReverse.isPresent}
                className={`${styles.col} ${styles.present}`}/>
              {isEdit
                && <div className={styles.col}>
                </div>
              }
            </div>
            {sortedList?.map((l, i) => (
              <ParticipantRow
                key={uniqid()}
                index={i}
                participant={l}
                styles={styles}
                users={users}
                isEdit={isEdit}
                participants={participants}
                handleChange={handleChange}
              />
            ))}
          </>
        }
        {isEdit
          && <div className={styles.form}>
              <div className={styles.col}>
                <StyledSelect
                  control={control}
                  name="user"
                  placeholder="Séléctionner"
                  onChange={(opt) => setNewParticipent({
                    ...newParticipant,
                    user: opt.value,
                    isPresent: false,
                  })}
                  options={users.filter((u) => !participants.find((p) => p.user === u.value))}
                  />
              </div>
              <div className={styles.col}>
                <DatePicker
                  name="date"
                  control={control}
                  className={'primary'}
                  placeholder="Séléctionner"
                />
              </div>
              <div className={styles.col}>
                <button
                  onClick={() => handleChange([...participants, { ...newParticipant, date: getValues('date') || new Date() }])}
                  className={newParticipant?.user && watch('date') ? `${styles.active} ${styles.submit}` : styles.submit}
                  >
                  <BsCheckLg />
                </button>
              </div>
          </div>
        }
        {isEdit
          && <button
            onClick={() => setAddDivision(true)}
            className={styles.add}
          >
            Ajouter une division
          </button>
        }
        {isEdit && addDivision
          && <div className={styles.form}>
              <div className={styles.col}>
                <StyledSelect
                  control={control}
                  name="division"
                  placeholder="Séléctionner"
                  onChange={(opt) => setNewDivision({ ...newDivision, division: opt.value })}
                  options={divisionsList}
                  />
              </div>
              <div className={styles.col}>
                <DatePicker
                  name="date-division"
                  control={control}
                  className={'primary'}
                  placeholder="Séléctionner"
                />
              </div>
              <div className={styles.col}>
                <button
                  onClick={() => handleChangeDivision({ ...newDivision, date: getValues('date-division') || new Date() })}
                  className={newDivision?.division && watch('date-division') ? `${styles.active} ${styles.submit}` : styles.submit}
                  >
                  <BsCheckLg />
                </button>
              </div>
          </div>
        }
      </div>
    </div>
  );
}
