/* eslint-disable no-confusing-arrow */
import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FiLogOut } from 'react-icons/fi';
import logo from '../../assets/images/logo.svg';

import styles from './Nav.module.scss';
import { getTrainingsListAction, getTrainingsOptionsListAction } from '../../actions/trainingsActions';

export default function Nav({ logout }) {
  const { userAuth, error, toast } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error === 401) {
      logout();
    }
  }, [error]);

  useEffect(() => {
    if (userAuth) {
      getTrainingsListAction(dispatch);
      getTrainingsOptionsListAction(dispatch);
    }
  }, [userAuth, toast]);

  return (
    <>
      <nav className={`${styles.nav} ${location.pathname === '/login' || location.pathname === '/' ? '' : styles['nav-is-active']}`}>
        <div className={styles.content}>

          <NavLink to="/training" className={styles.logo}>
            <img src={logo} alt="medocta" />
            <h2>OCTAFORM</h2>
          </NavLink>

          <div className={styles.links}>
            <NavLink to="/training" className={(navData) => navData.isActive ? styles['is-active'] : ''} >FORMATIONS</NavLink>
            <NavLink to="/planning" className={(navData) => navData.isActive ? styles['is-active'] : ''} >Planning</NavLink>
          </div>

          <div className={styles.icons}>

            <button className={styles.logout} onClick={() => logout()}>
              <FiLogOut size={22} color={'#192243'}/>
            </button>

          </div>

        </div>
      </nav>
    </>

  );
}
