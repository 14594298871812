import React, { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import SortedList from '../SortedList/SortedList';
import styles from './GroupedList.module.scss';

export default function GroupedList({
  group,
  lists,
  type,
  // index,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef();
  // useEffect(() => {
  //   let timer = null;
  //   if (listRef?.current?.clientHeight > 0 && index === 0) {
  //     timer = setTimeout(() => {
  //       setIsOpen(true);
  //     }, 100);
  //   } else {
  //     setIsOpen(false);
  //   }
  //   return () => clearTimeout(timer);
  // }, [listRef?.current?.clientHeight]);
  return (
    <div className={styles.container}>
      <button
        className={styles.name}
        onClick={() => setIsOpen(!isOpen)}
        >
        <div className={`${styles.icon} ${isOpen ? styles.open : ''}`}>
          <FiChevronDown size={22} />
        </div>
        <p>{group.name} ({group.list.length})</p>
      </button>
      <div
        className={styles.content}
        style={{
          height: isOpen && listRef?.current?.clientHeight ? listRef.current.clientHeight + 30 : 0,
        }}
        >
        <div ref={listRef}>
        <SortedList
          lists={lists}
          trainingsList={group.list}
          type={type}
          />
        </div>
      </div>
    </div>
  );
}
