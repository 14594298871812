import React from 'react';
import uniqid from 'uniqid';
import PlanningRow from '../PlanningRow/PlanningRow';
import styles from './GroupedPlanning.module.scss';

export default function GroupedPlanning({
  list,
  name,
  yearWidth,
  scrollPos,
  datesCount,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <PlanningRow
          key={uniqid()}
          planning={list || []}
          width={(yearWidth / 12) * datesCount}
        />
        <div
          className={styles.group}
          style={{
            marginLeft: scrollPos.x - 350,
          }}
          >
          <div
            className={styles.name}
          >
            {name}
          </div>
        </div>
      </div>
    </div>
  );
}
