import React, { useState, useEffect } from 'react';
import SortButton from '../../lib/SortButton';
import TrainingRow from '../TrainingRow/TrainingRow';
import styles from './SortedList.module.scss';

export default function SortedList({
  lists,
  type,
  trainingsList,
}) {
  const initSortState = {
    name: true,
    ref: true,
    type: true,
    drug: true,
    validityDate: true,
    therapeuticArea: true,
  };

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [sortedList, setSortedList] = useState();

  function sortList(sortType, isReverse) {
    let list = [...sortedList];
    switch (sortType) {
      case 'name':
        list = list.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, name: isReverse });
        break;

      case 'ref':
        list = list.sort((a, b) => {
          if (a.ref < b.ref) return -1;
          if (a.ref > b.ref) return 1;
          return 0;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, ref: isReverse });
        break;

      case 'type':
        list = list.sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, type: isReverse });
        break;

      case 'therapeuticArea':
        list = list.sort((a, b) => {
          if (a.therapeuticArea < b.therapeuticArea) return -1;
          if (a.therapeuticArea > b.therapeuticArea) return 1;
          return 0;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, therapeuticArea: isReverse });
        break;

      case 'drug':
        list = list.sort((a, b) => {
          if (a.drug < b.drug) return -1;
          if (a.drug > b.drug) return 1;
          return 0;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, drug: isReverse });
        break;

      case 'date':
        list = list.sort((a, b) => {
          const aDate = a.date
            ? new Date(a.date) : new Date();
          const bDate = b.date
            ? new Date(b.date) : new Date();
          return aDate - bDate;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, date: isReverse });
        break;

      case 'validityDate':
        list = list.sort((a, b) => {
          const aDate = a.validityDate
            ? new Date(a.validityDate) : new Date();
          const bDate = b.validityDate
            ? new Date(b.validityDate) : new Date();
          return aDate - bDate;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, validityDate: isReverse });
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    if (trainingsList) {
      console.log(type);
      setSortedList(trainingsList);
    }
  }, [trainingsList]);

  return (
    <>
      <div className={`${styles.row} ${styles.label}`}>
        <SortButton
          name={'Nom de la formation'}
          handleClick={() => sortList('name', !sortByReverse.name)}
          state={sortByReverse.name}
          className={`${styles.col} ${styles.name}`}/>
        <SortButton
          name={'Référence'}
          handleClick={() => sortList('ref', !sortByReverse.ref)}
          state={sortByReverse.ref}
          className={`${styles.col}`}/>
        <SortButton
          name={'Type'}
          handleClick={() => sortList('type', !sortByReverse.type)}
          state={sortByReverse.type}
          className={`${styles.col}`}/>
        <SortButton
          name={'Produit'}
          handleClick={() => sortList('drug', !sortByReverse.drug)}
          state={sortByReverse.drug}
          className={`${styles.col}`}/>
        <SortButton
          name={'Aire thérapeutique'}
          handleClick={() => sortList('therapeuticArea', !sortByReverse.therapeuticArea)}
          state={sortByReverse.therapeuticArea}
          className={`${styles.col}`}/>
        {type === 'person'
          ? <>
            <SortButton
              name={'Date prévue'}
              handleClick={() => sortList('date', !sortByReverse.date)}
              state={sortByReverse.date}
              className={`${styles.col}`}/>
          </>
          : <>
            <SortButton
              name={'Date de validité'}
              handleClick={() => sortList('validityDate', !sortByReverse.validityDate)}
              state={sortByReverse.validityDate}
              className={`${styles.col}`}/>
          </>
        }
      </div>

      {sortedList?.map((t) => (<TrainingRow
        key={t.key || t._id}
        styles={styles}
        type={type}
        lists={lists}
        training={t}
      />))}
    </>
  );
}
