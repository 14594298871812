import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { BsCheckLg } from 'react-icons/bs';
import { AiFillDelete } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import DatePicker from '../../../../lib/HooksFormFields/DatePicker';

export default function ParticipantRow({
  participant,
  styles,
  index,
  users,
  isEdit,
  participants,
  handleChange,
}) {
  const {
    control,
    setValue,
    watch,
    // setError,
  } = useForm();
  const deleteClass = `${styles.col} ${styles.delete}`;
  const divisions = users.find((u) => u.value === participant.user)?.divisions?.sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  function handleChangeParticipant(key, value) {
    const updatedList = [...participants];
    updatedList[index] = { ...participant, [key]: value };
    setValue(key, value);
    handleChange(updatedList);
  }

  useEffect(() => {
    if (participant.date) {
      setValue('date', new Date(participant.date));
    }
    if (participant.isPresent) {
      setValue('isPresent', participant.isPresent);
    }
  }, []);

  return (
    <div className={styles.row} id={participant._id}>
      <div className={styles.col}>
        <p>{users.find((u) => u.value === participant.user)?.label}</p>
      </div>
      <div className={styles.col}>
        <p>{divisions?.map((d, i) => <span key={d + i + participant.user}> {d} {i < divisions.length - 1 && '/' }</span>)}</p>
      </div>
      <div className={`${styles.col} ${styles.date}`}>
        {isEdit
          ? <>
              <div className={`${styles['input-date']}`}>
                <DatePicker
                  name="date"
                  control={control}
                  className={'secondary'}
                  placeholder="Séléctionner"
                />
              </div>
              <button
                onClick={() => handleChangeParticipant('date', watch('date'))}
                className={`${styles.active} ${styles.submit} ${styles.edit}`}
                >
                <BsCheckLg />
              </button>
            </>
          : <p>{format(new Date(participant.date), 'dd/MM/yyyy')}</p>
        }
      </div>
      <div className={`${styles.col} ${styles.present}`}>
        <button
          onClick={() => handleChangeParticipant('isPresent', !watch('isPresent'))}
          className={`${styles.submit} ${watch('isPresent') ? styles.present : styles.edit}`}
          >
          <BsCheckLg />
        </button>
      </div>
      {isEdit
        && <div className={deleteClass}>
          <button
            onClick={() => handleChange(participants?.filter((p) => p._id !== participant._id))}
            >
            <AiFillDelete/>
          </button>
        </div>
      }
    </div>
  );
}
