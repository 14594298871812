import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from './input-text.module.scss';

const InputText = ({
  name,
  control,
  rules,
  defaultValue = '',
  widthLabel = null,
  inline = false,
  label = null,
  placeholder,
  className,
}) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => (
          <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input']}`}>
            { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            <input
              ref={ref}
              value={value}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              className={styles.input}
              placeholder={placeholder}
            />
          </div>
      )}
    />

);

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputText;
