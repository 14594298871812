import React, { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';

import styles from './Links.module.scss';

export default function Links({
  links,
  isEdit,
  handleChange,
}) {
  const [value, setValue] = useState('');
  function addLink() {
    handleChange([...links, value]);
    setValue('');
  }
  return (
    <div className={styles.container}>
      <h2>Liens</h2>
      <div className={styles.links}>
        {links.map((l, i) => {
          const key = `url${i}`;
          return (
            <div key={key} className={styles.link}>
              <a href={l} target='_blank' rel="noreferrer">
                {l}
              </a>
              {isEdit
                && <button
                  type="button"
                  onClick={() => handleChange(links.filter((u) => u !== l))}
                  className={`${styles.delete}`}>
                  <AiFillDelete size={20} />
                </button>
              }
            </div>
          );
        })}
      </div>
      {isEdit
        && <div className={styles.input}>
          <input value={value} onChange={(e) => setValue(e.target.value)}/>
          {value
            && <button
              type="button"
              className={styles.add}
              onClick={() => addLink()}>
              <p>Ajouter</p>
            </button>
          }
        </div>
      }
    </div>
  );
}
