import { createStore, combineReducers } from 'redux';
import authReducer from '../reducers/authReducer';
import trainingsReducer from '../reducers/trainingsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  trainings: trainingsReducer,
});

export default createStore(rootReducer);
