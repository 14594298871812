const style = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    borderColor: '#BBBBBB',
    backgroundColor: '#F3F3F3',
    borderRadius: 3,
    minHeight: 39,
    maxHeight: 39,
    '&:hover': {
      borderColor: state.isFocused ? '#D06666' : '',
    },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px' }),
  valueContainer: (provided) => ({ ...provided }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px', marginTop: -2 }),
  multiValue: (provided) => ({ ...provided, fontSize: '14px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({ ...provided }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    backgroundColor: state.isSelected ? '#00142C' : '',
    '&:hover': {
      backgroundColor: '#D06666',
      color: 'white',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({ ...provided }),
};

export default style;
