import React, { useEffect, useState } from 'react';
import { BiLink } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTrainingsOptionsListAction } from '../../../../actions/trainingsActions';
import InputText from '../../../../lib/HooksFormFields/InputText';
import Textarea from '../../../../lib/HooksFormFields/Textarea';
import StyledSelect from '../../../../lib/HooksFormFields/StyledSelect';
import styles from './Header.module.scss';

export default function Header({
  isEdit,
  watch,
  control,
  setValue,
  submit,
  lists,
}) {
  const dispatch = useDispatch();
  const [isCreated, setIsCreated] = useState();
  const { trainingsList, training } = useSelector((state) => state.trainings);
  const drugs = lists?.drugs.map((d) => ({ value: d._id, label: d.name })) || [];
  let courses = lists?.courses.map((d) => ({ value: d, label: d })) || [];
  const types = lists?.types || [];
  const quizOptions = trainingsList?.map((t) => ({ value: t._id, label: t.ref || t.name })) || [];
  const therapeuticAreas = lists?.therapeuticAreas.map((d) => ({ value: d._id, label: d.name }))
  || [];

  function handleCreateCourse(input) {
    courses = [...courses, { label: input, value: input }];
    setValue('course', input);
    submit({ course: input });
    setIsCreated(input);
  }

  useEffect(() => {
    if (isCreated) {
      getTrainingsOptionsListAction(dispatch);
    }
  }, [isCreated]);

  return (
    <section className={styles.container}>
      {isEdit
        ? <div className={styles.info}>
          <div className={styles.name}>
            <label>Nom de la formation</label>
            <InputText
              name='name'
              control={control}
              className={'primary'}
              placeholder={'nom de la formation'}
            />
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <label>Référence</label>
              <InputText
                name='ref'
                control={control}
                className={'primary'}
                placeholder={'référence'}
              />
            </div>
            <div className={styles.col}>
              <label>Type</label>
              <StyledSelect
                control={control}
                name="type"
                placeholder="Séléctionner une option..."
                value={types.find((opt) => opt.value === watch('type'))}
                onChange={(opt) => setValue('type', opt.value)}
                options={types}
                />
            </div>
            <div className={styles.col}>
              <label>Parcours</label>
              <StyledSelect
                control={control}
                name="course"
                placeholder="Séléctionner une option..."
                value={courses.find((opt) => opt.value === watch('course'))}
                onChange={(opt) => setValue('course', opt.value)}
                options={courses}
                isCreatable={true}
                formatCreateLabel={(input) => `Ajouter le parcours "${input}"` }
                onCreateOption={(val) => handleCreateCourse(val)}
                />
            </div>
          </div>

          <div className={styles.url}>
            <label>Lien vers le Share Point</label>
            <InputText
              name='sharepointUrl'
              control={control}
              className={'primary'}
              placeholder={'url'}
            />
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <label>Produit</label>
              <StyledSelect
                control={control}
                name="drug"
                placeholder="Séléctionner une option..."
                value={drugs.find((opt) => opt.value === watch('drug'))}
                onChange={(opt) => setValue('drug', opt.value)}
                options={drugs}
                />
            </div>
            <div className={styles.col}>
              <label>Aire thérapeutique</label>
              <StyledSelect
                control={control}
                name="therapeuticArea"
                placeholder="Séléctionner une option..."
                value={therapeuticAreas.find((opt) => opt.value === watch('therapeuticArea'))}
                onChange={(opt) => setValue('therapeuticArea', opt.value)}
                options={therapeuticAreas}
                />
            </div>
            <div className={styles.col}>
              <label>Quiz</label>
              <StyledSelect
                control={control}
                name="quiz"
                placeholder="Séléctionner une option..."
                value={quizOptions.find((opt) => opt.value === watch('quiz') || opt.value === watch('quiz')?._id)}
                onChange={(opt) => setValue('quiz', opt.value)}
                options={quizOptions}
                />
            </div>
          </div>

          <div className={`${styles.row} ${styles.border}`}>
            <div className={styles.col}>
              <label>Nom du formateur</label>
              <InputText
                name='former.firstName'
                control={control}
                className={'primary'}
              />
            </div>
            <div className={styles.col}>
              <label>Prénom du formateur</label>
              <InputText
                name='former.lastName'
                control={control}
                className={'primary'}
              />
            </div>
            <div className={styles.col}>
              <label>Email du formateur</label>
              <InputText
                name='former.email'
                control={control}
                className={'primary'}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <label>Description</label>
              <Textarea
                name='description'
                control={control}
                className={'primary'}
              />

            </div>
            <div></div>
          </div>

        </div>
        : <div className={styles.info}>
          <div className={styles.name}>
            <label>Nom de la formation</label>
            <h3>{watch('name')}</h3>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <label>Référence</label>
              <p>{watch('ref')}</p>
            </div>
            <div className={styles.col}>
              <label>Type</label>
              <p>{watch('type')}</p>
            </div>
            <div className={styles.col}>
              <label>Parcours</label>
              <p>{watch('course')}</p>
            </div>
          </div>

          {watch('sharepointUrl')
            && <div className={`${styles.row} ${styles.block}`}>
              <div className={`${styles.col}`}>
              <label>Lien vers le Share Point</label>
              <a href={watch('sharepointUrl')} className={styles.link} target='_blank' rel="noreferrer">
                <BiLink size={20} />{watch('sharepointUrl')}
              </a>
            </div>
          </div>
          }

          <div className={`${styles.row} ${styles.block} ${styles.product}`}>
            {watch('drug')
              && <div className={`${styles.col}`}>
                <label>Produit concerné</label>
                <p>{drugs?.find((opt) => opt.value === watch('drug'))?.label}</p>
              </div>
            }
            {watch('therapeuticArea')
              && <div className={`${styles.col}`}>
                <label>Aire thérapeutique</label>
                <p>{therapeuticAreas?.find((opt) => opt.value === watch('therapeuticArea'))?.label}</p>
              </div>
            }
            {watch('quiz')?._id
              && <div className={`${styles.col}`}>
                <label>Quiz</label>
                <Link to={`/training/${watch('quiz')?._id}`}>
                  <p><BiLink size={20} /><span>{watch('quiz').ref || watch('quiz').name}</span></p>
                </Link>
              </div>
            }
            {training?.linkedTraining
              && <div className={`${styles.col}`}>
                <label>Formation</label>
                <Link to={`/training/${training?.linkedTraining?._id}`}>
                  <p><BiLink size={20} /><span>{training?.linkedTraining?.name}</span></p>
                </Link>
              </div>
            }
          </div>

          <div className={`${styles.row} ${styles.border}`}>
            <div className={styles.col}>
              <label>Formateur </label>
              {watch('former.lastName') || watch('former.email')
                ? <p>{watch('former.lastName')} {watch('former.firstName')} {watch('former.email') && ` - ${watch('former.email')}`}</p>
                : <p>Formateur innconu</p>
              }
            </div>
            <div></div>
          </div>

          {watch('description')
            && <div className={styles.row}>
              <div className={styles.col}>
                <label>Descritption </label>
                <p className={styles.desc}>{watch('description')}</p>
              </div>
              <div></div>
            </div>
          }

        </div>
      }
    </section>
  );
}
