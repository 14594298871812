import React from 'react';
import { useDispatch } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import { getFileAction } from '../../actions/trainingsActions';

export default function FileItem({
  styles,
  file,
  deleteFile,
  isEdit,
}) {
  const dispatch = useDispatch();
  return (
    <div className={styles.file}>
      <button onClick={() => getFileAction(dispatch, file._id)}>
        <p>{file.name}</p>
      </button>
      {isEdit
       && <button
        type="button"
        onClick={() => deleteFile(file._id)}
        className={`${styles.delete}`}>
        <AiFillDelete size={20} />
      </button>
      }
    </div>
  );
}
