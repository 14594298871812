// AUTH ACTIONS
export const ERROR_AUTH = 'ERROR_AUTH';
export const USER_AUTH = 'USER_AUTH';
export const MESSAGE_AUTH = 'MESSAGE_AUTH';
export const SET_TOAST = 'SET_TOAST';
// TRAININGS ACTIONS
export const ERROR_TRAININGS = 'ERROR_TRAININGS';
export const GET_TRAININGS = 'GET_TRAININGS';
export const GET_TRAINING = 'GET_TRAINING';
export const PUT_TRAINING = 'PUT_TRAINING';
export const DELETE_FILE = 'DELETE_FILE';
export const POST_FILE = 'POST_FILE';
export const POST_TRAINING = 'POST_TRAINING';
export const DELETE_TRAINING = 'DELETE_TRAINING';
export const SET_TRAINING_FILTERS = 'SET_TRAINING_FILTERS';
export const GET_DRUGS = 'GET_DRUGS';
export const EXPORT_TRAINING = 'EXPORT_TRAINING';
export const SET_PLANNING_FILTERS = 'SET_PLANNING_FILTERS';
export const GET_TRAININGS_OPTIONS = 'GET_TRAININGS_OPTIONS';
