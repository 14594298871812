import React, { useEffect, useCallback } from 'react';
import { useTransition, animated } from 'react-spring';
import {
  Routes, Route, useLocation, useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAuthAction } from '../actions/authActions';

import Login from './Auth/Login';
import Toaster from '../lib/Toaster/Toaster';
import Nav from '../components/Nav';
import TrainingsList from './TrainingsList/TrainingsList';
import Training from './Training/Training';
import Planning from './Planning/Planning';

export default function GlobalRouter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const transitions = useTransition(location, {
    from: { opacity: 1, transform: 'translate3d(-120%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(120%, 0,0)' },
  });
  const { toast } = useSelector((state) => state.auth);
  const token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  }, [dispatch]);

  function logout() {
    navigate('/login');
    localStorage.removeItem('token');
    dispatch({
      type: 'USER_AUTH',
      payload: null,
    });
  }

  useEffect(() => {
    if (token) {
      getUser();
    }
    if (location.pathname !== '/login' && location.pathname !== '/') {
      getUser();
    }
  }, []);

  return (
    <>
      <Nav
        logout={logout}
        />
      {transitions((props, item, key) => (
        <animated.div key={key} style={props}>
          <Routes location={item}>

            <Route exact path='/login' element={<Login />}/>

            <Route exact path='/training' element={<TrainingsList />}/>

            <Route exact path='/training/create' element={<Training />}/>

            <Route exact path='/training/:id' element={<Training />}/>

            <Route exact path='/planning' element={<Planning />}/>

            <Route path='*' element={<Login />}/>

          </Routes>
        </animated.div>
      ))}
      <Toaster toast={toast} dispatch={dispatch}/>
    </>
  );
}
