import { saveAs } from 'file-saver';

import {
  getData, putData, postData, getFile, deleteData,
} from './index';

import {
  ERROR_TRAININGS,
  GET_TRAININGS_OPTIONS,
  GET_TRAININGS,
  GET_TRAINING,
  PUT_TRAINING,
  POST_TRAINING,
  EXPORT_TRAINING,
  DELETE_TRAINING,
  SET_TOAST,
  POST_FILE,
  DELETE_FILE,
} from './types';

export async function getTrainingsListAction(dispatch) {
  const url = '/training';
  let trainings;
  await getData(ERROR_TRAININGS, url, dispatch, true).then((response) => {
    trainings = response.trainings;
  });
  dispatch({
    type: GET_TRAININGS,
    payload: trainings,
  });
}

export async function getTrainingsOptionsListAction(dispatch) {
  const url = '/training/list';
  let lists;
  await getData(ERROR_TRAININGS, url, dispatch, true).then((response) => {
    lists = response.lists;
  });
  dispatch({
    type: GET_TRAININGS_OPTIONS,
    payload: lists,
  });
}

export async function getTrainingAction(dispatch, id) {
  const url = `/training/${id}`;
  let training;
  await getData(ERROR_TRAININGS, url, dispatch, true).then((response) => {
    training = { ...response.training, linkedTraining: response.linkedTraining || null };
  });
  dispatch({
    type: GET_TRAINING,
    payload: training,
  });
}

export async function putTrainingAction(dispatch, id, data) {
  const url = `/training/${id}`;
  let training;

  await putData(PUT_TRAINING, ERROR_TRAININGS, url, dispatch, data, true).then((response) => {
    training = response.training;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'la formation a été modifiée',
    },
  });

  dispatch({
    type: PUT_TRAINING,
    payload: training,
  });
}

export async function postTrainingAction(dispatch, data) {
  const url = '/training';
  let training;

  await postData(POST_TRAINING, ERROR_TRAININGS, url, dispatch, data, true).then((response) => {
    training = response.training;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'la formation a été créée',
    },
  });

  dispatch({
    type: POST_TRAINING,
    payload: training,
  });
}

export async function deleteTrainingAction(dispatch, id) {
  const url = `/training/${id}`;
  let variation;

  await deleteData(ERROR_TRAININGS, url, dispatch).then((response) => {
    variation = response;
  });
  if (variation) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'la formation a été supprimée',
      },
    });
    dispatch({
      type: DELETE_TRAINING,
      payload: id,
    });
  }
}

export async function postFileAction(dispatch, formData) {
  const url = '/file';
  let file;

  await postData(POST_FILE, ERROR_TRAININGS, url, dispatch, formData, true).then((response) => {
    file = response;
  });

  dispatch({
    type: POST_FILE,
    payload: file.files[0],
  });
}

export async function deleteFileAction(dispatch, id) {
  const url = `/file/${id}`;
  let file;

  await deleteData(ERROR_TRAININGS, url, dispatch).then((response) => {
    file = response;
  });

  dispatch({
    type: DELETE_FILE,
    payload: {
      file: null,
      message: `${file.message} ${id}`,
    },
  });
}

export async function getFileAction(dispatch, id, downloadName = false, fileType = null) {
  const url = `/file/download/${id}`;

  await getFile(ERROR_TRAININGS, url, dispatch, true).then((response) => {
    if (!downloadName && !fileType) {
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL);
    } else {
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      saveAs(blob, downloadName);
    }
  });
}

export async function exportTrainingsAction(dispatch) {
  const url = '/training/export';
  let exportData;
  await getData(ERROR_TRAININGS, url, dispatch, true).then((response) => {
    exportData = response;
  });
  dispatch({
    type: EXPORT_TRAINING,
    payload: exportData,
  });
}
